<template>
  <div id="app" class="url" style="padding: 15px">
    <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="账号权限分配"> </el-page-header>
      </el-col>
    </el-row>

    <el-row style="margin: 10px 0">
      <el-button
        type="success"
        icon="el-icon-check"
        @click="dialogVisible = true"
        >选择公司</el-button
      >
      <el-button
        :disabled="usable"
        type="success"
        icon="el-icon-plus"
        @click="dialogVisible1 = true"
        >添加账号</el-button
      >
    </el-row>

    <!-- 公司信息列表 -->
    <el-dialog
      title="公司信息"
      :visible.sync="dialogVisible"
      width="40%"
      append-to-body
      :close-on-click-modal="false"
    >
      <div style="height: 260px; overflow-y: scroll">
        <el-tree
          :data="allCompanyList"
          show-checkbox
          node-key="id"
          ref="tree"
          highlight-current
          :props="defaultProps"
          accordion
        >
        </el-tree>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedKeys">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加人员选择 弹框 -->
    <el-dialog
      title="添加账号"
      :visible.sync="dialogVisible1"
      width="30%"
      :close-on-click-modal="false"
    >
    <div>
        <table>
          <tr>
            <td>
              <el-tag style="font-size: 16px; margin: 0 10px 10px 0; height:35px;line-height:35px"
                >账号名称</el-tag
              >
            </td>
            <td style="width: 100%">
              <el-input
                v-model="userName"
                placeholder="请 填 写 账 号 名 称"
              ></el-input>
            </td>
          </tr>
          
        </table>
      </div>
       <div slot="footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="addAccount">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 给添加的人  添加权限  弹框-->
    <el-dialog
      title="编辑账号权限"
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      width="40%"
    >
      
        <el-checkbox-group v-model="checkboxGroup" >
              <el-checkbox class="checkBox" v-if="item.name!=null &&item.name!=undefined" v-for="item in checkboxGroup" :label="item.name" border></el-checkbox>
        </el-checkbox-group>
           
      <span slot="footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedNodes">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="账号权限分配"
      :visible.sync="toAllocate"
      width="40%">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 公司对应的 人员信息列表 -->
    <el-table border :data="accountList" style="width: 100%" height="500px">
      <el-table-column align="center" label="序号" type="index" width="100">
        </el-table-column>
        <el-table-column align="center" prop="userName" label="账号名称">
        </el-table-column>
        </el-table-column> -->
        
        <el-table-column align="center" fixed="right" label="操作" width="180">
        <!-- 添加权限 -->
        <template slot-scope="scope">
          <el-button
          type="primary"
          plain
          size="mini"
          @click="editPersonnel(scope.row)"
          >编辑</el-button
        >
        <el-button type="danger" plain size="mini" @click="delPersonnel(scope.row)"
          >删除</el-button
        >
        </template>
        
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

import user from "@/api/manageApi/User"
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'

// 公司系统
import companySystem from "@/api/managementApi/CompanyInfo";

export default {
  data() {
    return {
      sendUserName:"",
      submitList:[],
      checkboxGroup:[],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      toAllocate: false,
      usable: true,

      allCompanyList:[],    // 公司树
      
      checkedKeys: "", // 通过key获取被选择的公司id

      userName: '',   // 添加账号

      defaultProps: {   // 公司树
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },
      
      // 公司对应人员列表
      accountList: [],
    };
  },
  created() {
    this.checkboxGroupInit();
    this.getLoginUserInfo()
    // this.getMenuList()  // 根据用户名返回所需要的菜单list
    this.getAllCompanyList(); // 查询到所有公司列表
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    checkboxGroupInit(){
      this.checkboxGroup = [{
        userName:"show",
        name: "主页",
        status: true
      },{
        userName:"deviceInfoManagement",
        name: "我的设备",
        status: false
      },{
        userName:"crtShow",
        name: "CRT管理",
      },{
        userName:"history",
        name: "历史记录",
      },{
        userName:"mapBuiding",
        name: "数据可视化",
      },{
        userName:"subscribeManagement",
        name: "订阅管理",
      },{
        userName:"permissionSysManagement",
        name: "管理系统",
      },{
        userName:"OperationLog",
        name: "操作日志",
      }]
    },


    // 获取当前登录用户的信息，要显示的菜单
    getLoginUserInfo(){
      user.loginUserInfo().then(res=>{
        // console.log(res);
      })
    },

    // 添加用户权限
    addUserPermissions(){
      this.toAllocate = true;
    },

    // 编辑按钮
    editPersonnel(row){
      this.checkboxGroupInit();
      this.sendUserName = row.userName;
      LoginUserJuris.getMenuFromUserName(row.userName).then(res=>{

      for(let i in res.data.data.list){
         for(let j in this.checkboxGroup){
            if(this.checkboxGroup[j].userName != null && this.checkboxGroup[j].userName != undefined){
              if(res.data.data.list[i] == this.checkboxGroup[j].userName){
                this.checkboxGroup.push(this.checkboxGroup[j].name)
                break
              }
            }
          }
      }
      })
      this.dialogVisible2 = true
      // this.getAllCommonNotifyList()
    },

    // 查询所有公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        this.allCompanyList = res.data.data.list;
      });
    },

    // 选择公司  确定按钮
    getCheckedKeys() {
      this.checkedKeys = this.$refs.tree.getCheckedKeys();
      if (this.$refs.tree.getCheckedKeys() == "") {
        alert("请选择公司");
      } else {
        this.dialogVisible = false;
        // 通过选择的公司 id 查所有账号
        this.getAllAccountList(this.checkedKeys);
        // 添加人员 按钮是否可用
        this.usable = false; 
      }
    },

    // 通过公司id获取 所有账号     ------------***-----------
    getAllAccountList() {
      let companyIds = this.checkedKeys;
      LoginUserJuris.getUserNameFromCompanyId(companyIds).then((res) => {
  
        this.accountList = res.data.data.list.map(item =>({
          userName:item
        }))

      });
    },

// 传编辑通知方式选中的对象id
    getCheckedNodes() {

      // 选择的菜单项
      this.submitList = [];
      for(let i in this.checkboxGroup){
        if(this.checkboxGroup[i].name == null || this.checkboxGroup[i].name == undefined){
          for(let j in this.checkboxGroup){
            if(this.checkboxGroup[j].name != null && this.checkboxGroup[j].name != undefined){
              if(this.checkboxGroup[i] == this.checkboxGroup[j].name){
                this.submitList.push(this.checkboxGroup[j].userName)
              }
            }
          }
        }
      }
      // 打印选择的菜单项
      let menus = this.submitList;
      let userName = this.sendUserName;
      LoginUserJuris.addMenuFromUserNameList(menus,userName).then(res=>{
        this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
      })
       this.dialogVisible2 = false;
    },


       // 添加人员  确定按钮
    addAccount() {
      let companyIds = this.checkedKeys;
      let userName = this.userName
      if ( this.userName == "") {
        this.$message.error("账号名称不能为空！");
      } else {
          LoginUserJuris.addLoginUserJurisRecord(companyIds,userName).then(() => {
          this.dialogVisible1 = false;
          this.userName = "";
          this.getAllAccountList(this.checkedKeys);
        });
      }
    },
    
    // 删除人员         （根据公司查到的人员列表）
    delPersonnel(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        LoginUserJuris.deleteLoginUserJurisRecord(row.userName).then((res) => {
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //重新加载账号列表
          this.getAllAccountList(this.checkedKeys);
          
        });
      });
    },
  },
};
</script>



<style lang="scss" scoped>
  .add-item{
    margin-top: 10px;
  }
  .checkBtn{
    margin-left: 60px;
  }
  .select-item{
    width: 85%;
  }


    .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 100%;
    height:250px;
    overflow-y: scroll;
  }
  .checkBox{
    margin: 10px;
  }
</style>