import request from '@/../utils/request'
const api_name = '/iotechserver/loginUserJuris'

export default {

    // 通过公司id获取 所有账号  *   
    getUserNameFromCompanyId(companyIds) {
        return request({
            url: `${api_name}/getUserNameFromCompanyId/${companyIds}`,
            method: 'get'
        })
    },

    // 传账号名 返回需要的菜单 List   *
    getMenuFromUserName(userName) {
        return request({
            url: `${api_name}/getMenuFromUserName/${userName}`,
            method: 'get'
        })
    },

    // 通过 userName 删除账号下的所有记录   *
    deleteLoginUserJurisRecord(userName) {
        return request({
            url: `${api_name}/deleteLoginUserJurisRecord/${userName}`,
            method: 'delete'
        })
    },

    // 通过公司 id 和 userName 添加   *
    addLoginUserJurisRecord(companyIds, userName) {
        return request({
            url: `${api_name}/addLoginUserJurisRecord/${companyIds}/${userName}`,
            methos: 'get'
        })
    },

    // 传公司列表 和 userName      删除
    deleteRecordFromCompanyIdsUserName(companyIds, userName) {
        return request({
            url: `${api_name}/deleteRecordFromCompanyIdsUserName/${companyIds}/${userName}`,
            method: 'delete'
        })
    },

    // 通过菜单和用户名添加
    addMenuFromUserNameList(menus, userName) {
        return request({
            url: `${api_name}/addMenuFromUserNameList/${menus}/${userName}`,
            method: 'get'
        })
    },
}